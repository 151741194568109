import React, { Component } from 'react';
import Calendar from 'react-calendar';
import { ScheduleContext } from '../contexts/ScheduleContext';
import 'react-calendar/dist/Calendar.css';
import './Schedule.css';

class Schedule extends Component {
    static contextType = ScheduleContext;

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            selectedWorkout: '',
            recurrence: 'weekly',
            selectedDays: [],
            message: '',
        };

        this.daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    }

    tasksForDate(date) {
        const { schedules } = this.context;
        return schedules.filter((schedule) => {
            const scheduleDate = new Date(schedule.originDate);

            if (schedule.recurrenceType === 'none') {
                return scheduleDate.toDateString() === date.toDateString();
            }

            if (schedule.recurrenceType === 'daily') {
                return scheduleDate <= date;
            }

            if (schedule.recurrenceType === 'weekly') {
                const dayOfWeek = date.getDay();
                return scheduleDate <= date && schedule.recurrenceDays.includes(dayOfWeek);
            }

            if (schedule.recurrenceType === 'monthly') {
                return scheduleDate.getDate() === date.getDate() && scheduleDate <= date;
            }
            return false;
        });
    }

	async deleteSchedule(id) {
		const { deleteSchedule } = this.context;
		try {
            await deleteSchedule(id);
            this.setState({
                message: 'Schedule deleted successfully!',
                selectedWorkout: '',
                recurrence: 'weekly',
                selectedDays: [],
            });
        } catch (error) {
            console.error('Error saving schedule:', error);
            this.setState({ message: 'Failed to save schedule.' });
        }
	}

    async saveSchedule() {
        const { addSchedule } = this.context;
        const { selectedWorkout, selectedDate, recurrence, selectedDays } = this.state;

        console.log(selectedDays)
        if (!selectedWorkout || !selectedDate || !recurrence || (recurrence =='weekly' && !selectedDays.length)) {
            this.setState({ message: 'Please fill in all fields.' });
            return;
        }

        const scheduleData = {
            workoutId: selectedWorkout,
            originDate: selectedDate.toISOString().split('T')[0],
            recurrenceType: recurrence,
            recurrenceDays: recurrence === 'weekly' ? selectedDays : [],
        };

        try {
            await addSchedule(scheduleData);
            this.setState({
                message: 'Schedule added successfully!',
                selectedWorkout: '',
                recurrence: 'weekly',
                selectedDays: [],
            });
        } catch (error) {
            console.error('Error saving schedule:', error);
            this.setState({ message: 'Failed to save schedule.' });
        }
    }

    render() {
		const { selectedDate, selectedWorkout, recurrence, selectedDays, message } = this.state;
        const { workouts } = this.context;

        const currentEvents = this.tasksForDate(selectedDate);

        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <h1>Schedule</h1>

                <div style={{ marginBottom: '20px' }}>
                    <Calendar
                        onChange={(date) => this.setState({ selectedDate: date })}
                        value={selectedDate}
                        tileContent={({ date }) => {
                            return this.tasksForDate(date).length ? (
                                <div
                                    style={{
                                        marginTop: '5px',
                                        backgroundColor: '#007bff',
                                        color: 'white',
                                        borderRadius: '50%',
                                        width: '10px',
                                        height: '10px',
                                        margin: 'auto',
                                    }}
                                />
                            ) : null;
                        }}
                    />
                </div>

                <h3>Tasks for {selectedDate.toDateString()}</h3>
                {currentEvents.length > 0 ? (
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {currentEvents.map((schedule) => (
                            <li
                                key={schedule.id}
                                style={{
                                    padding: '8px',
                                    borderBottom: '1px solid #eee',
                                    textAlign: 'left',
                                    display: 'inline-block',
                                    width: '300px',
                                }}
                            >
                                {workouts.find((workout) => workout.id === schedule.workoutId)?.name || 'Unknown Workout'}{' '}
                                ({schedule.recurrenceType})
                                <button
                                    onClick={() => this.deleteSchedule(schedule.id)}
                                    style={{
                                        marginLeft: '10px',
                                        padding: '5px 10px',
                                        fontSize: '12px',
                                        backgroundColor: '#ff4d4d',
                                        color: '#fff',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Delete
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No tasks for this date.</p>
                )}

                <div style={{ marginTop: '20px' }}>
                    <label style={{ fontWeight: 'bold', marginRight: '10px' }}>
                        Select Workout:
                        <select
                            value={selectedWorkout}
                            onChange={(e) => this.setState({ selectedWorkout: e.target.value })}
                            style={{
                                padding: '10px',
                                width: '300px',
                                fontSize: '16px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                marginRight: '10px',
                            }}
                        >
                            <option value="">-- Select a Workout --</option>
                            {workouts.map((workout) => (
                                <option key={workout.id} value={workout.id}>
                                    {workout.name}
                                </option>
                            ))}
                        </select>
                    </label>
                    <select
                        value={recurrence}
                        onChange={(e) => this.setState({ recurrence: e.target.value })}
                        style={{
                            padding: '10px',
                            fontSize: '16px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            marginRight: '10px',
                        }}
                    >
                        <option value="none">No Recurrence</option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                    </select>

                    {recurrence === 'weekly' && (
                        <div className="weekly-days-container">
                            {this.daysOfWeek.map((day, index) => (
                                <label key={index}>
                                    <input
                                        type="checkbox"
                                        checked={selectedDays.includes(index)}
                                        onChange={() =>
                                            this.setState((prevState) => ({
                                                selectedDays: prevState.selectedDays.includes(index)
                                                    ? prevState.selectedDays.filter((d) => d !== index)
                                                    : [...prevState.selectedDays, index],
                                            }))
                                        }
                                    />
                                    {day}
                                </label>
                            ))}
                        </div>
                    )}

                    <button
                        onClick={() => this.saveSchedule()}
                        style={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            backgroundColor: '#007bff',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                        }}
                    >
                        Add Workout
                    </button>
                </div>
                {message && <p>{message}</p>}
            </div>
        );
    }
}

export default Schedule;
