import React, { Component } from 'react';
import { ScheduleContext } from '../contexts/ScheduleContext';
import { LogsContext } from '../contexts/LogsContext';
import './Log.css';

class Log extends Component {
    static contextType = ScheduleContext;

    constructor(props) {
        super(props);
        this.state = {
            currentDate: new Date(),
            selectedWorkout: '',
            newFoodName: '',
            newFoodProtein: '',
        };
        this.getLogByDateFromContext = null;
    }

    componentDidMount() {
        if (this.getLogByDateFromContext) {
            const dateStr = this.formatDateForBackend(this.state.currentDate);
            this.getLogByDateFromContext(dateStr);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentDate !== this.state.currentDate && this.getLogByDateFromContext) {
            const dateStr = this.formatDateForBackend(this.state.currentDate);
            this.getLogByDateFromContext(dateStr);
        }
    }

    changeDate = (days) => {
        this.setState((prevState) => {
            const newDate = new Date(prevState.currentDate);
            newDate.setDate(newDate.getDate() + days);
            return { currentDate: newDate, selectedWorkout: '', newFoodName: '', newFoodProtein: '' };
        });
    };

    tasksForDate = (date, schedules, currentWorkouts) => {
        return schedules
            .filter((schedule) => this.context.isEventOnDate(schedule, date))
            .filter((schedule) => {
                const workoutId = schedule.workoutId;
                return !currentWorkouts.some((workout) => workout.id === workoutId);
            });
    };

    formatDateForBackend(date) {
        return date.toISOString().split('T')[0];
    }

    addTaskWorkout = (addWorkoutToDate, dateStr, task, currentWorkouts) => {
        const workoutId = task.workoutId;
        if (!workoutId) return;

        if (currentWorkouts.some((workout) => workout.id === workoutId)) {
            console.warn(`Workout with ID ${workoutId} is already in the log for ${dateStr}`);
            return;
        }

        addWorkoutToDate(dateStr, workoutId);
    };

    addSelectedWorkout = (addWorkoutToDate, dateStr, currentWorkouts) => {
        const { workouts } = this.context;
        const { selectedWorkout } = this.state;

        if (!selectedWorkout) return;

        const workoutToAdd = workouts.find((w) => w.id === selectedWorkout);

        if (workoutToAdd) {
            if (currentWorkouts.some((workout) => workout.id === workoutToAdd.id)) {
                console.warn(`Workout with ID ${workoutToAdd.id} is already in the log for ${dateStr}`);
                return;
            }

            addWorkoutToDate(dateStr, workoutToAdd.id);
        }
    };

    render() {
        const { currentDate, selectedWorkout, newFoodName, newFoodProtein } = this.state;
        const { schedules, workouts } = this.context;

        const formattedDate = currentDate.toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });

        const dateStr = this.formatDateForBackend(currentDate);

        return (
            <LogsContext.Consumer>
                {({
                    logs,
                    getLogByDate,
                    addWorkoutToDate,
                    loading,
                    error,
                    removeWorkoutFromDate,
                    addFoodToDate,
                    removeFoodFromDate,
                }) => {
                    this.getLogByDateFromContext = getLogByDate;

                    if (loading) {
                        return <p>Loading logs...</p>;
                    }

                    if (error) {
                        return <p>Error loading logs: {error}</p>;
                    }

                    const currentLog = logs.find((l) => l.date === dateStr);
                    const displayedWorkouts = currentLog && currentLog.workouts ? currentLog.workouts : [];
                    const tasksForCurrentDate = this.tasksForDate(currentDate, schedules, displayedWorkouts);

                    return (
                        <div className="log-container">
                            <div className="log-header">
                                <button onClick={() => this.changeDate(-1)} className="arrow-button">←</button>
                                {formattedDate}
                                <button onClick={() => this.changeDate(1)} className="arrow-button">→</button>
                            </div>

                            {tasksForCurrentDate.length > 0 ? (
                                <ul className="tasks-list">
                                    {tasksForCurrentDate.map((task, index) => (
                                        <li key={index} className="task-item">
                                            <div className="task-header">
                                                <div>
                                                    <strong>{task.workoutName}</strong>{' '}
                                                    {task.recurrenceType !== 'none' && `(${task.recurrenceType})`}
                                                </div>
                                                <button
                                                    onClick={() =>
                                                        this.addTaskWorkout(addWorkoutToDate, dateStr, task, displayedWorkouts)
                                                    }
                                                    className="add-button"
                                                >
                                                    +
                                                </button>
                                            </div>
                                            {task.workoutExercises && (
                                                <ul className="task-exercises">
                                                    {task.workoutExercises.map((exercise, idx) => (
                                                        <li key={idx}>{exercise.trim()}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            ) : null}

                            <h2 className="log-title">Log</h2>
                            {displayedWorkouts.length > 0 ? (
                                <ul className="log-list">
                                    {displayedWorkouts.map((loggedWorkout, i) => (
                                        <li key={i} className="log-item">
                                            <strong>{loggedWorkout.name}</strong>
                                            <button
                                                className="remove-button"
                                                onClick={() => removeWorkoutFromDate(dateStr, loggedWorkout.id)}
                                            >
                                                X
                                            </button>
                                            {loggedWorkout.exercises && (
                                                <ul className="task-exercises">
                                                    {loggedWorkout.exercises.map((exercise, idx) => (
                                                        <li key={idx}>{exercise.trim()}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No workouts logged yet.</p>
                            )}

                            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <select
                                    value={selectedWorkout}
                                    onChange={(e) => this.setState({ selectedWorkout: Number(e.target.value) })}
                                    style={{ padding: '0.5rem', borderRadius: '4px' }}
                                >
                                    <option value="">-- Select a workout --</option>
                                    {workouts &&
                                        workouts
                                            .filter(
                                                (workout) =>
                                                    !displayedWorkouts.some((loggedWorkout) => loggedWorkout.id === workout.id)
                                            )
                                            .map((workout) => (
                                                <option key={workout.id} value={workout.id}>
                                                    {workout.name}
                                                </option>
                                            ))}
                                </select>
                                <button
                                    onClick={() => this.addSelectedWorkout(addWorkoutToDate, dateStr, displayedWorkouts)}
                                    className="add-button"
                                    style={{ marginLeft: '10px' }}
                                >
                                    +
                                </button>
                            </div>

                            <h3 className="log-title">Foods</h3>
                            {currentLog && currentLog.foods && currentLog.foods.length > 0 ? (
                                <ul className="food-list">
                                    {currentLog.foods.map((food, index) => (
                                        <li key={index} className="food-item">
                                            <div className="food-header">
                                                <strong>{food.name}</strong> - {food.proteinAmount}g protein
                                                <button
                                                    className="remove-button"
                                                    onClick={() => removeFoodFromDate(dateStr, food.id)}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No foods logged yet.</p>
                            )}
                            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="Food Name"
                                        value={newFoodName || ''}
                                        onChange={(e) => this.setState({ newFoodName: e.target.value })}
                                        style={{
                                            padding: '0.5rem',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            width: '200px',
                                        }}
                                    />
                                    <input
                                        type="number"
                                        placeholder="Protein (g)"
                                        value={newFoodProtein || ''}
                                        onChange={(e) => this.setState({ newFoodProtein: e.target.value })}
                                        style={{
                                            padding: '0.5rem',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            width: '100px',
                                        }}
                                    />
                                    <button
                                        onClick={() => {
                                            if (!newFoodName || !newFoodProtein) {
                                                alert('Please provide both food name and protein amount');
                                                return;
                                            }

                                            addFoodToDate(dateStr, {
                                                name: newFoodName,
                                                proteinAmount: parseFloat(newFoodProtein),
                                            });

                                            this.setState({ newFoodName: '', newFoodProtein: '' });
                                        }}
                                        className="add-button"
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </LogsContext.Consumer>
        );
    }
}

export default Log;
