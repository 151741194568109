import React, { Component } from 'react';
import { WorkoutsContext } from '../contexts/WorkoutsContext';
import './Workouts.css';

class Workouts extends Component {
    static contextType = WorkoutsContext; // Link the context

    constructor(props) {
        super(props);
        this.state = {
            message: '',
        };
    }

    async handleDeleteWorkout(workoutId) {
        const { deleteWorkout } = this.context;
    
        const confirmDelete = window.confirm('Are you sure you want to delete this workout?');
        if (!confirmDelete) return;
    
        try {
            await deleteWorkout(workoutId);
            this.setState({ message: 'Workout deleted successfully!' });
        } catch (error) {
            console.error('Error deleting workout:', error);
            this.setState({ message: 'Failed to delete workout.' });
        }
    };
    
    async handleAddWorkout(name, exercises) {
        const { addWorkout } = this.context; // Access addWorkout from context
        try {
            const newWorkout = {
                name,
                exercises: exercises ? exercises.split(',').map((exercise) => exercise.trim()) : [],
            };

            await addWorkout(newWorkout);
            this.setState({ message: 'Workout added successfully!' });
        } catch (error) {
            console.error('Error adding workout:', error);
            this.setState({ message: 'Failed to add workout.' });
        }
    };

    render() {
        const { workouts, loading, error } = this.context; // Access context data

        if (loading) {
            return <p>Loading workouts...</p>;
        }

        if (error) {
            return <p>Error loading workouts: {error}</p>;
        }

        return (
            <div className="workouts-container">
                <h1>Workouts</h1>

                {workouts.length > 0 ? (
                    <ul className="workout-list">
                        {workouts.map((workout) => (
                            <li key={workout.id} className="workout-item">
                                <div className="workout-header">
                                    <strong>{workout.name}</strong>
                                    
                                </div>
                                {workout.exercises && (
                                    <ul className="workout-exercises">
                                        {workout.exercises.map((exercise, index) => (
                                            <li key={index}>{exercise.trim()}</li>
                                        ))}
                                    </ul>
                                )}

                                <button
                                        className="delete-button"
                                        onClick={() => this.handleDeleteWorkout(workout.id)}
                                > X</button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No workouts available.</p>
                )}

                <div className="workout-form">
                    <h2>Add New Workout</h2>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            const name = e.target.elements.name.value.trim();
                            const exercises = e.target.elements.exercises.value.trim();
                            if (name) {
                                this.handleAddWorkout(name, exercises);
                                e.target.reset();
                            }
                        }}
                    >
                        <div className="form-group">
                            <label htmlFor="name">Workout Name:</label>
                            <input id="name" type="text" placeholder="Enter workout name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exercises">Exercises (comma-separated):</label>
                            <input
                                id="exercises"
                                type="text"
                                placeholder="e.g., Push-up, Squat, Plank"
                            />
                        </div>
                        <button type="submit" className="add-button">
                            Add Workout
                        </button>
                    </form>
                </div>
                {this.state.message && <p>{this.state.message}</p>}
            </div>
        );
    }
}

export default Workouts;
