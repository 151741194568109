import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ScheduleProvider } from './contexts/ScheduleContext';
import { WorkoutsProvider } from './contexts/WorkoutsContext';
import { LogsProvider } from './contexts/LogsContext'; // Import LogsProvider

import Home from './components/Home';
import Log from './components/Log';
import Schedule from './components/Schedule';
import Workouts from './components/Workouts';
import './App.css';

const App = () => {
    return (
        <WorkoutsProvider>
            <ScheduleProvider>
                <LogsProvider>
                    <Router>
                        <nav>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/log">Log</Link></li>
                                <li><Link to="/schedule">Schedule</Link></li>
                                <li><Link to="/workouts">Workouts</Link></li>
                            </ul>
                        </nav>

                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/log" element={<Log />} />
                            <Route path="/schedule" element={<Schedule />} />
                            <Route path="/workouts" element={<Workouts />} />
                        </Routes>
                    </Router>
                </LogsProvider>
            </ScheduleProvider>
        </WorkoutsProvider>
    );
};

export default App;
