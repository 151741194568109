import React, { Component, createContext } from 'react';
import { WorkoutsContext } from '../contexts/WorkoutsContext'; // Import WorkoutsContext

export const LogsContext = createContext();

class LogsProvider extends Component {
    static contextType = WorkoutsContext; // Access workouts from WorkoutsContext

    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            loading: false,
            error: null,
        };
        this.API_URL = process.env.REACT_APP_API_URL; 
        this.API_KEY = process.env.REACT_APP_API_KEY;
    }

    setLoading = (loading) => {
        this.setState({ loading });
    };

    setError = (error) => {
        this.setState({ error, loading: false });
    };

    mergeFetchedLog = (logData) => {
        this.setState((prevState) => {
            const logs = [...prevState.logs];
            const existingIndex = logs.findIndex((l) => l.date === logData.date);
            if (existingIndex !== -1) {
                logs[existingIndex] = logData;
            } else {
                logs.push(logData);
            }
            return { logs, loading: false, error: null };
        });
    };

    getLogByDate = async (date) => {
        try {
            const response = await fetch(`${this.API_URL}/logs/${date}`, {
                headers: { 'x-api-key': this.API_KEY },
            });
            if (response.status === 204) {
                this.setState((prevState) => ({
                    logs: prevState.logs.filter((l) => l.date !== date),
                    loading: false,
                    error: null
                }));
                return null;
            }

            if (!response.ok) throw new Error(`Failed to fetch log for date ${date}`);

            const logData = await response.json();
            this.mergeFetchedLog(logData);
            return logData;
        } catch (error) {
            console.error('Error fetching log by date:', error);
            this.setError(error.message);
            return null;
        }
    };

    ensureLocalLogForDate = (date) => {
        let logs = [...this.state.logs];
        let logIndex = logs.findIndex((l) => l.date === date);

        if (logIndex === -1) {
            const newLog = {
                id: null,
                date,
                workouts: [],
                foods: [],
                createdAt: null,
                updatedAt: null,
            };
            logs.push(newLog);
            logIndex = logs.length - 1;
        }

        return { logs, logIndex };
    };

    updateLogInState = (updatedLog) => {
        this.setState((prevState) => {
            const logs = [...prevState.logs];
            const index = logs.findIndex((l) => l.date === updatedLog.date);
            if (index !== -1) {
                logs[index] = updatedLog;
            } else {
                logs.push(updatedLog);
            }
            return { logs, loading: false, error: null };
        });
    };

    addWorkoutToDate = async (date, workoutId) => {
        try {
            const response = await fetch(`${this.API_URL}/logs/workouts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': this.API_KEY,
                },
                body: JSON.stringify({ workoutId, date }),
            });

            if (!response.ok) throw new Error('Failed to add workout to log');

            // Look up the workout name and exercises from WorkoutsContext
            const { workouts } = this.context;
            const workout = workouts.find((w) => w.id === workoutId);

            if (!workout) {
                throw new Error(`Workout with ID ${workoutId} not found`);
            }

            const workoutObj = {
                id: workoutId,
                name: workout.name,
                exercises: workout.exercises || [],
            };

            // Update the local log with the workout
            const { logs, logIndex } = this.ensureLocalLogForDate(date);
            const updatedLog = { ...logs[logIndex] };
            updatedLog.workouts = updatedLog.workouts ? [...updatedLog.workouts, workoutObj] : [workoutObj];

            this.updateLogInState(updatedLog);
        } catch (error) {
            console.error('Error adding workout to log:', error);
            this.setError(error.message);
        }
    };

    removeWorkoutFromDate = async (date, workoutId) => {
        try {
            const response = await fetch(`${this.API_URL}/logs/workouts`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': this.API_KEY,
                },
                body: JSON.stringify({ workoutId, date }),
            });

            if (!response.ok) throw new Error('Failed to remove workout from log');

            const { logs, logIndex } = this.ensureLocalLogForDate(date);
            const updatedLog = { ...logs[logIndex] };
            updatedLog.workouts = updatedLog.workouts.filter((w) => w.id !== workoutId);

            this.updateLogInState(updatedLog);
        } catch (error) {
            console.error('Error removing workout from log:', error);
            this.setError(error.message);
        }
    };

    // Add food to a specific date
    addFoodToDate = async (date, food) => {
        // this.setLoading(true);
        try {
            const response = await fetch(`${this.API_URL}/logs/foods`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': this.API_KEY,
                },
                body: JSON.stringify({ date, ...food }),
            });

            if (!response.ok) throw new Error('Failed to add food to log');

            // Update the local log with the new food
            const { logs, logIndex } = this.ensureLocalLogForDate(date);
            const updatedLog = { ...logs[logIndex] };
            response.json().then(({foodId}) => {
                const foodObj = {
                    id: foodId, // Temporary ID for the new food
                    name: food.name,
                    proteinAmount: food.proteinAmount,
                };
    
                updatedLog.foods = updatedLog.foods ? [...updatedLog.foods, foodObj] : [foodObj];
                this.updateLogInState(updatedLog);
            })
        } catch (error) {
            console.error('Error adding food to log:', error);
            this.setError(error.message);
        }
    };

    // Remove food from a specific date
    removeFoodFromDate = async (date, foodId) => {
        // this.setLoading(true);
        try {
            const response = await fetch(`${this.API_URL}/logs/foods`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': this.API_KEY,
                },
                body: JSON.stringify({ date, foodId }),
            });

            if (!response.ok) throw new Error('Failed to remove food from log');

            // Update local state to remove the food item
            this.setState((prevState) => {
                const logs = [...prevState.logs];
                const logIndex = logs.findIndex((log) => log.date === date);
                if (logIndex !== -1) {
                    const updatedLog = { ...logs[logIndex] };
                    updatedLog.foods = updatedLog.foods.filter((food) => food.id !== foodId);
                    logs[logIndex] = updatedLog;
                }
                return { logs, loading: false, error: null };
            });
        } catch (error) {
            console.error('Error removing food from log:', error);
            this.setError(error.message);
        }
    };

    render() {
        const { logs, loading, error } = this.state;

        return (
            <LogsContext.Provider
                value={{
                    logs,
                    loading,
                    error,
                    getLogByDate: this.getLogByDate,
                    addWorkoutToDate: this.addWorkoutToDate,
                    removeWorkoutFromDate: this.removeWorkoutFromDate,
                    addFoodToDate: this.addFoodToDate,
                    removeFoodFromDate: this.removeFoodFromDate
                }}
            >
                {this.props.children}
            </LogsContext.Provider>
        );
    }
}

export { LogsProvider };
