import React, { createContext, useState, useEffect } from 'react';

export const WorkoutsContext = createContext();

export const WorkoutsProvider = ({ children }) => {
    const [workouts, setWorkouts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const API_URL = process.env.REACT_APP_API_URL;
    const API_KEY = process.env.REACT_APP_API_KEY;

    useEffect(() => {
        fetchWorkouts();
    }, []);

    const addWorkout = async (newWorkout) => {
        try {
            const response = await fetch(`${API_URL}/workouts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': API_KEY,
                },
                body: JSON.stringify(newWorkout),
            });
    
            if (!response.ok) throw new Error('Failed to add workout');
    
            const createdWorkout = await response.json();
            setWorkouts((prev) => [...prev, createdWorkout]);
            return createdWorkout;
        } catch (error) {
            console.error('Error adding workout:', error);
            throw error;
        }
    };
    
    const fetchWorkouts = async () => {
        try {
            const response = await fetch(`${API_URL}/workouts`, {
                headers: { 'x-api-key': API_KEY },
            });

            if (!response.ok) {
                throw new Error(`Error fetching workouts: ${response.status}`);
            }

            const data = await response.json();
            setWorkouts(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const deleteWorkout = async (workoutId) => {
        const API_URL = process.env.REACT_APP_API_URL;
        const API_KEY = process.env.REACT_APP_API_KEY;
    
        try {
            const response = await fetch(`${API_URL}/workouts/${workoutId}`, {
                method: 'DELETE',
                headers: {
                    'x-api-key': API_KEY,
                },
            });
    
            if (!response.ok) throw new Error('Failed to delete workout');
    
            setWorkouts((prev) => prev.filter((workout) => workout.id !== workoutId));
        } catch (error) {
            console.error('Error deleting workout:', error);
            throw error;
        }
    };
    

    return (
        <WorkoutsContext.Provider value={{ workouts, loading, error, addWorkout, deleteWorkout }}>
            {children}
        </WorkoutsContext.Provider>
    );
};
