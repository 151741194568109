import React, { Component, createContext } from 'react';
import { WorkoutsContext } from './WorkoutsContext';

export const ScheduleContext = createContext();

class ScheduleProvider extends Component {
    static contextType = WorkoutsContext; // Access workouts context

    constructor(props) {
        super(props);
        this.state = {
            schedules: [],
            loading: true,
            error: null,
        };

        this.API_URL = process.env.REACT_APP_API_URL;
        this.API_KEY = process.env.REACT_APP_API_KEY;
    }

    componentDidMount() {
        this.fetchSchedules();
    }
    
    fetchSchedules = async () => {
        try {
            const response = await fetch(`${this.API_URL}/schedules`, {
                headers: { 'x-api-key': this.API_KEY },
            });
            if (!response.ok) throw new Error('Failed to fetch schedules');
            const data = await response.json();
            this.setState({ schedules: data });
        } catch (error) {
            this.setState({ error: error.message });
        } finally {
            this.setState({ loading: false });
        }
    };

    addSchedule = async (schedule) => {
        try {
            const response = await fetch(`${this.API_URL}/schedules`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': this.API_KEY,
                },
                body: JSON.stringify(schedule),
            });
            if (!response.ok) throw new Error('Failed to add schedule');
            const newSchedule = await response.json();
            newSchedule.workoutName = this.context.workouts.find(e => e.id = newSchedule.workoutId).name
            console.log(newSchedule)
            this.setState((prevState) => ({
                schedules: [...prevState.schedules, newSchedule],
            }));
            return newSchedule;
        } catch (error) {
            console.error('Error adding schedule:', error);
        }
    };

    deleteSchedule = async (id) => {
        try {
            const response = await fetch(`${this.API_URL}/schedules/${id}`, {
                method: 'DELETE',
                headers: { 'x-api-key': this.API_KEY },
            });
            if (!response.ok) throw new Error('Failed to delete schedule');
            this.setState((prevState) => ({
                schedules: prevState.schedules.filter((schedule) => schedule.id !== id),
            }));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    isEventOnDate = (schedule, date) => {
        const scheduleDate = new Date(schedule.originDate);

        if (schedule.recurrenceType === 'none') {
            return scheduleDate.toDateString() === date.toDateString();
        }

        if (schedule.recurrenceType === 'daily') {
            return scheduleDate <= date;
        }

        if (schedule.recurrenceType === 'weekly') {
            const dayOfWeek = date.getDay();
            return (
                scheduleDate <= date &&
                Array.isArray(schedule.recurrenceDays) &&
                schedule.recurrenceDays.includes(dayOfWeek)
            );
        }

        if (schedule.recurrenceType === 'monthly') {
            return scheduleDate.getDate() === date.getDate() && scheduleDate <= date;
        }

        return false;
    };

    render() {
        const { schedules, loading, error } = this.state;
        const { workouts } = this.context;

        return (
            <ScheduleContext.Provider
                value={{
                    schedules,
                    workouts, // Pass workouts from WorkoutsContext
                    loading,
                    error,
                    addSchedule: this.addSchedule,
                    deleteSchedule: this.deleteSchedule,
                    isEventOnDate: this.isEventOnDate,
                }}
            >
                {this.props.children}
            </ScheduleContext.Provider>
        );
    }
}

export { ScheduleProvider };
